import {StoresWidgetID} from '@wix/wixstores-client-core/dist/es/src/constants';

export class StyleParams {
  constructor(private readonly sdk: IEditorSdk) {}

  public async setCheckoutStyleParam(type: string, key: string, param: {value: any}) {
    const allComponentsRefs: ComponentRef[] = await this.sdk.document.components.getAllComponents('');
    const allComponents: ComponentSpec[] = await this.sdk.document.components.get('', {
      componentRefs: allComponentsRefs,
      properties: ['data'],
    });
    const checkoutWidget = allComponents.find((component) => component.data?.widgetId === StoresWidgetID.CHECKOUT);
    if (!checkoutWidget) {
      return;
    }

    await this.sdk.tpa.setStyleParams('', {
      compRef: checkoutWidget.componentRef,
      styleParams: [{type, key, param}],
    });
  }
}
